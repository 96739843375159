<template>
	<aside class="app-filter-sidebar">
		<h1 class="h5 font-weight-normal ml-2">
			<a
				href="javascript:"
				class="btn btn-outline-secondary btn-sm btn-circle btn-circle-sm btn-table"
				title="Fechar Filtro"
				data-toggle="filter-sidebar"
			>
				<i class="fa fa-arrow-right"></i>
			</a>
			<i class="fa fa-filter ml-5"></i> Filtro
		</h1>
		<hr class="mb-2"/>
		<div class="row m-0 filter">
			<div
				v-for="filter in filters"
				class="col-12"
				:class="{ 'input-daterange' : (filter.type === 'range') }"
			>
				<div v-if="filter.type === 'input'" class="form-group">
					<input
						class="form-control form-control-sm"
						:placeholder="filter.label"
						:index="filter.index"
						:disabled="filter.disabled"
						:name="filter.name"
						v-model="filter.value"
						@change="onChange"
						type="date"
					>
				</div>

				<div v-if="filter.type === 'range'">
					<div
						class="input-group input-group-sm mb-3"
						:id="filter.name + 'RangeDate0'"
					>
						<div class="input-group-append">
							<span v-if="filter.label0" class="input-group-text">
								{{ filter.label0 }}
							</span>
							<span v-if="!filter.label0" class="input-group-text">De</span>
						</div>
						<input
							class="form-control"
							autocomplete="off"
							type="text"
							:name="filter.name + '0'"
							:value="filter.value"
							@change="onChange"
						>
					</div>
					<div
						class="input-group input-group-sm mb-3"
						:id="filter.name + 'RangeDate1'"
					>
						<div class="input-group-append">
							<span v-if="filter.label1" class="input-group-text">
								{{ filter.label1 }}
							</span>
							<span v-if="!filter.label1" class="input-group-text">Até</span>
						</div>
						<input
							class="form-control"
							autocomplete="off"
							type="text"
							:name="filter.name + '1'"
							:value="filter.value"
							@change="onChange"
						>
					</div>
				</div>

				<div v-if="filter.type === 'slider'" class="form-group">
					<label>{{ filter.label }}</label>
					<input
						type="text"
						v-model="filter.value"
						data-slider-min="0"
						data-slider-max="100"
						data-slider-step="5"
						data-slider-value="[0,100]"
						:id="filter.name + 'Slider'"
						@change="onChange"
					>
				</div>

				<div v-if="filter.type === 'select'" class="form-group">
					<select
						:class="{ 'selectpicker' : filter.liveSearch }"
						class="form-control form-control-sm"
						:data-live-search="filter.liveSearch"
						:data-live-search-normalize="true"
						:data-none-selected-text="'-'"
						:index="filter.index"
						:disabled="filter.disabled"
						:name="filter.name"
						v-model="filter.value"
						@change="onChange"
					>
						<option v-if="filter.label" value="" selected>
							{{ filter.label }}
						</option>
						<option
							v-for="option in filter.options"
							:value="option.value"
							:selected="(filter.value == option.value)"
						>
							{{ option.label }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
	export default {
		data() {
			return {
				filters: [],
			};
		},
		props: {
			onChangeCallback: Function
		},
		mounted() {
			this.addScripts([
				'/assets/js/plugins/bootstrap-slider.js',
			], this.callback);
		},
		methods: {
			async add(data) {
				data.value = data.hasOwnProperty('value') ? data.value : '';
				this.filters.push(data);

				await Help.delay(300);

				this.callback();
			},
			callback() {
				$('.filter')
						.find('[id*="Slider"]')
						.each((i, o) => {
							$(o).slider({});
						});

				$('.input-daterange')
						.datepicker({
							format: "dd/mm/yyyy",
							language: "pt-BR",
							todayHighlight: true,
							clearBtn: true,
							autoclose: true,
						})
						.change(this.onChange);

				$('[data-toggle="filter-sidebar"]')
						.off()
						.click((e) => {
							e.preventDefault();
							$('.app').toggleClass('sidefilter-toggled');
						});
			},
			onChange() {
				let data = [];

				for (let filter of this.filters) {
					data.push({ name: filter.name, value: filter.value });

					if (filter.callback && filter.value) {
						filter.callback(filter);
					}
				}

				this.onChangeCallback(data);
			},
			async setNewOptions(fieldName, data) {
				let newFilter = this.filters.filter(f => f.name == fieldName)[0];
				newFilter.options = data;
				await Help.delay(300);

				$('.selectpicker').selectpicker('refresh');
			}
		}
	}
</script>
