var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-exclamation","title":"Denúncias","breadcrumbs":[
            { title: 'Painel' }
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"complaints","options":{
            ajax: {
                url: '/web/complaints/list'
            },
            columns: this.columns
        },"actions":{
            edit: {
                allow: true,
                url: 'javascript:',
                callback: _vm.onEdit,
            },
        }}}),_vm._v(" "),_c('app-complaint-edit',{ref:"complaintEdit",attrs:{"onUpdate":_vm.reloadTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }