<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/social.jpg"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        >
                            <div>
                                <h1
                                    class="font-weight-bold d-none d-xl-block"
                                    data-aos="fade-down"
                                >
                                    Educar é a mais nobre das ações humanas
                                </h1>
                                <img
                                    class="img-fluid p-3 carisma-logo"
                                    data-aos="zoom-in"
                                    src="/assets/img/carisma_logo.png"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 method">
                        <h1 class="h2 font-weight-bold text-center mb-3">
                            Pequenas ações geram grandes mudanças
                        </h1>
                        <p class="text-justify mb-4">
                            <img
                                class="float-right m-3 mb-5 img-fluid img-thumbnail"
                                data-aos="zoom-in-up"
                                src="/assets/img/carisma1.jpg"
                            >
                            Acreditamos grandemente no poder transformador da educação e na
                            inﬂuência que uma boa formação terá sobre a vida de uma pessoa ao
                            longo de toda a sua vida. Sabemos que a qualidade da educação no
                            primeiro ciclo de vida é fundamental para o desenvolvimento da
                            criança e do vínculo afetivo que ela estabelece com o signiﬁcado do
                            aprender.<br>
                            Diante de nossas crenças e convicções, o que realmente importa é a
                            ação. Palavras não são nada sem a devida ação. São ações que
                            transformam o mundo e contribuem efetivamente para um amanhã mais
                            digno e justo.<br>
                            Desde o primeiro dia, consideramos extremamente importante dar nossa
                            modesta contribuição para a transformação social da educação.<br>
                            Escolhemos o Centro Social Carisma para o início dos nossos projetos
                            sociais, por se tratar de uma instituição séria, certificada e que,
                            realmente, acolhe crianças e jovens de braços abertos, na certeza de
                            que é possível transformar realidades através de uma boa formação e
                            de qualificação profissional.
                        </p>
                        <p class="text-justify">
                            <img
                                title="Assistir"
                                data-aos="zoom-in-up"
                                class="float-left m-3 img-thumbnail bt-video img-fluid"
                                src="/assets/img/carisma2.jpg"
                                @click="openVideo"
                            >
                            O Centro Social Carisma atende, aproximadamente, 120 crianças, em um
                            dos maiores bolsões de pobreza do município de Osasco, em São Paulo,
                            identiﬁcado com área Y - formado pelo Jardim Padroeira, Jardim São
                            Pedro e a região de Quitaúna.<br>
                            Esse território tem, como características marcantes, a falta de
                            saneamento básico, a existência de construções precárias e um alto
                            índice de desemprego, o que se traduz em alto grau de vulnerabilidade
                            da população mais jovem. No contato com uma população tão desprovida
                            de direitos, nosso compromisso é contribuir para que as crianças
                            tenham acesso a uma educação de qualidade, sintam-se valorizadas,
                            dotadas de talentos e inteligência, e, mais do que isso, passem a
                            vislumbrar oportunidades que poderão ter com sua dedicação e
                            compromisso com o sucesso!
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="social pt-5 pb-5">
            <div class="container text-white">
                <p class="text-justify">
                    "Rir muito e com frequência;<br>
                    ganhar o respeito de pessoas inteligentes e o afeto das crianças;<br>
                    merecer a consideração de críticos honestos e suportar a traição de falsos
                    amigos;<br>
                    apreciar a beleza, encontrar o melhor nos outros;<br>
                    deixar o mundo um pouco melhor, seja por uma saudável criança, um canteiro
                    de jardim ou uma redimida condição social;<br>
                    saber que ao menos uma vida respirou mais fácil porque você viveu. Isso é ter
                    tido sucesso."
                </p>
                <p class="text-right">- Ralph Waldo Emerson</p>
            </div>
        </section>

        <app-video-player ref="videoPlayer"></app-video-player>
    </div>
</template>

<script>
    import VideoPlayer from '../VideoPlayerComponent';

    export default {
        components: {
            'app-video-player': VideoPlayer
        },
        data() {
            return {
                VIDEO_ID: '6v2BUVzVN_4'
            };
        },
        methods: {
            openVideo() {
                this.$refs.videoPlayer.open(this.VIDEO_ID);
            }
        }
    }
</script>