var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('section',[_c('div',{staticClass:"carousel slide carousel-fade",attrs:{"data-ride":"carousel"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.images),function(image,index){return _c('div',{class:[
                        'carousel-item',
                        (index == 0) ? 'active' : ''
                    ]},[_c('img',{staticClass:"img-fluid w-100 banner-img",attrs:{"src":image}}),_vm._v(" "),_vm._m(1,true)])}),0)])]),_vm._v(" "),_c('section',{staticClass:"blackboard"},[_c('div',{staticClass:"container text-center"},[_c('div',{staticClass:"row"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-5 text-xl-right text-md-right align-self-center"},[_c('a',{staticClass:"btn btn-success btn-lg my-2",attrs:{"href":"javascript:"},on:{"click":_vm.openForm}},[_vm._v("\n                        Agende uma Visita\n                    ")])])])])]),_vm._v(" "),_c('app-contact',{ref:"contact"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"carousel-indicators"},[_c('li',{staticClass:"active",attrs:{"data-target":"#banner","data-slide-to":"0"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#banner","data-slide-to":"1"}}),_vm._v(" "),_c('li',{attrs:{"data-target":"#banner","data-slide-to":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-caption d-flex h-100 align-items-center justify-content-center m-0 p-0 t-0"},[_c('div',{staticClass:"user-select-none"},[_c('h1',{staticClass:"font-weight-bold d-none d-sm-block",attrs:{"data-aos":"fade-down"}},[_vm._v("\n                                FAÇA DA EDUCAÇÃO SUA MARCA DE GESTÃO\n                            ")]),_vm._v(" "),_c('p',{staticClass:"d-none d-md-block",attrs:{"data-aos":"fade-in"}},[_c('span',[_vm._v("Promova melhores resultados no ensino!")])]),_vm._v(" "),_c('p',{staticClass:"m-0 d-none d-xl-block",attrs:{"data-aos":"fade-in"}},[_vm._v("\n                                Democratize o acesso ao ensino de qualidade alinhado\n                                com a BNCC"),_c('br'),_vm._v("\n                                Revolucione o processo de aprendizagem com inovação,\n                                tecnologia,"),_c('br'),_vm._v("\n                                neurociência e os mais recentes avanços na área da educação\n                            ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-xl-9 col-md-7 d-flex"},[_c('p',{staticClass:"text-white m-0 text-xl-left text-md-left align-self-center"},[_vm._v("\n                        Vamos juntos transformar a EDUCAÇÃO!\n                    ")])])
}]

export { render, staticRenderFns }