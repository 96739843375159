<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
                        :disabled="saving"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
                <div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Protoloco</strong></p>
									<p>{{ data.protocol }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Denunciante</strong></p>
									<p>{{ data.name ?? 'Anônimo' }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>E-mail</strong></p>
									<p>{{ data.email ?? '-' }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Telefone</strong></p>
									<p>{{ data.phone ?? '-' }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Vínculo</strong></p>
									<p>{{ data.relationship ?? '-' }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Criada em</strong></p>
									<p>{{ new Date(data.created_at).toLocaleString() }}</p>
                                </div>
                                <div class="col-12">
                                    <p><strong>Descrição</strong></p>
									<p>{{ data.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="input-group input-group-sm col-md-4" v-if="!loading">
                        <div class="input-group-prepend">
                            <span class="input-group-text font-weight-bold">Status</span>
                        </div>
                        <select
                            name="status"
                            class="form-control form-control-sm"
                            title="Status"
                            v-model="status"
                            required
                            :disabled="saving"
                            @change="onChangeStatus"
                        >
                            <option
                                v-for="status in statusList"
                                :value="status.id"
                            >
                                {{ status.title }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                saving: false,
                data: null,
                statusList: [
                    { id: 0, title: 'Aberto' },
                    { id: 1, title: 'Em Análise' },
                    { id: 2, title: 'Concluído' },
                ],
                status: ''
            };
        },
        props: {
            defaultTitle: { type: String, default: 'Denúncia' },
            onUpdate: Function,
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/complaints/${id}`)
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                        this.title = `${this.defaultTitle}`;
                        this.status = this.data.status;
                    })
                    .catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			},
            onChangeStatus(e) {
                this.saving = true;

                axios.patch(`/web/complaints/${this.data.id}`, { status: this.status })
                    .then(res => {
                        this.saving = false;

                        Swal.fire({
                            type: 'success',
                            title: 'Atualizado',
                            text: 'Status atualizado com sucesso!'
                        });

                        this.onUpdate();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao atualizar status!');
                        this.saving = false;
                    });
            }
        }
    }
</script>
