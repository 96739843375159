<template>
    <div id="boxContent" class="modal-preview">
        <div class="modal-preview-bg" @click="close"></div>
        <a
            href="javascript:"
            class="btn btn-outline-danger btn-circle btn-table modal-preview-bt pt-0"
            @click="close"
        >
            <i class="fa fa-times"></i>
        </a>
        <div id="modalContent" class="modal-preview-content"></div>
    </div>
</template>

<script>
    export default {
        mounted() {
            var tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },
        methods: {
            open(videoID) {
                var box = document.getElementById('boxContent');
                box.style.display = 'block';

                var player = new YT.Player('modalContent', {
                    width: '768',
                    height: '450',
                    videoId: videoID,
                    playerVars: {
                        rel: 0,
                        showinfo: 0,
                        color: 'white',
                        theme: 'light',
                        fs: 0,
                        autohide: 1,
                        modestbranding: 1,
                        controls: 0
                    },
                    events: {
                        'onStateChange' : this.onPlayerStateChange,
                        'onReady' : this.onPlayerReady
                    }
                });
            },
            onPlayerReady(e) {
                e.target.setVolume(50);
                e.target.playVideo();
            },
            onPlayerStateChange(e) {
                if (e.data == 0) {
                    closePreview();
                }
            },
            close() {
                $('.modal-preview').hide();
                var lastPlayer = document.getElementById('modalContent');

                if (lastPlayer) {
                    var parent = lastPlayer.parentNode;
                    lastPlayer.remove();
                    var div = document.createElement('div');
                    div.id = 'modalContent';
                    div.className = 'modal-preview-content';
                    parent.appendChild(div);
                }
            }
        }
    }
</script>