var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('div',{staticClass:"carousel slide carousel-fade",attrs:{"id":"banner","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('img',{staticClass:"img-fluid w-100 banner-img",attrs:{"src":"/assets/img/social.jpg"}}),_vm._v(" "),_c('div',{class:[
                            'carousel-caption d-flex h-100 align-items-center',
                            'justify-content-center m-0 p-0 t-0'
                        ]},[_vm._m(0)])])])])]),_vm._v(" "),_c('section',{staticClass:"pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 method"},[_c('h1',{staticClass:"h2 font-weight-bold text-center mb-3"},[_vm._v("\n                        Pequenas ações geram grandes mudanças\n                    ")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',{staticClass:"text-justify"},[_c('img',{staticClass:"float-left m-3 img-thumbnail bt-video img-fluid",attrs:{"title":"Assistir","data-aos":"zoom-in-up","src":"/assets/img/carisma2.jpg"},on:{"click":_vm.openVideo}}),_vm._v("\n                        O Centro Social Carisma atende, aproximadamente, 120 crianças, em um\n                        dos maiores bolsões de pobreza do município de Osasco, em São Paulo,\n                        identiﬁcado com área Y - formado pelo Jardim Padroeira, Jardim São\n                        Pedro e a região de Quitaúna."),_c('br'),_vm._v("\n                        Esse território tem, como características marcantes, a falta de\n                        saneamento básico, a existência de construções precárias e um alto\n                        índice de desemprego, o que se traduz em alto grau de vulnerabilidade\n                        da população mais jovem. No contato com uma população tão desprovida\n                        de direitos, nosso compromisso é contribuir para que as crianças\n                        tenham acesso a uma educação de qualidade, sintam-se valorizadas,\n                        dotadas de talentos e inteligência, e, mais do que isso, passem a\n                        vislumbrar oportunidades que poderão ter com sua dedicação e\n                        compromisso com o sucesso!\n                    ")])])])])]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('app-video-player',{ref:"videoPlayer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"font-weight-bold d-none d-xl-block",attrs:{"data-aos":"fade-down"}},[_vm._v("\n                                Educar é a mais nobre das ações humanas\n                            ")]),_vm._v(" "),_c('img',{staticClass:"img-fluid p-3 carisma-logo",attrs:{"data-aos":"zoom-in","src":"/assets/img/carisma_logo.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-justify mb-4"},[_c('img',{staticClass:"float-right m-3 mb-5 img-fluid img-thumbnail",attrs:{"data-aos":"zoom-in-up","src":"/assets/img/carisma1.jpg"}}),_vm._v("\n                        Acreditamos grandemente no poder transformador da educação e na\n                        inﬂuência que uma boa formação terá sobre a vida de uma pessoa ao\n                        longo de toda a sua vida. Sabemos que a qualidade da educação no\n                        primeiro ciclo de vida é fundamental para o desenvolvimento da\n                        criança e do vínculo afetivo que ela estabelece com o signiﬁcado do\n                        aprender."),_c('br'),_vm._v("\n                        Diante de nossas crenças e convicções, o que realmente importa é a\n                        ação. Palavras não são nada sem a devida ação. São ações que\n                        transformam o mundo e contribuem efetivamente para um amanhã mais\n                        digno e justo."),_c('br'),_vm._v("\n                        Desde o primeiro dia, consideramos extremamente importante dar nossa\n                        modesta contribuição para a transformação social da educação."),_c('br'),_vm._v("\n                        Escolhemos o Centro Social Carisma para o início dos nossos projetos\n                        sociais, por se tratar de uma instituição séria, certificada e que,\n                        realmente, acolhe crianças e jovens de braços abertos, na certeza de\n                        que é possível transformar realidades através de uma boa formação e\n                        de qualificação profissional.\n                    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"social pt-5 pb-5"},[_c('div',{staticClass:"container text-white"},[_c('p',{staticClass:"text-justify"},[_vm._v("\n                \"Rir muito e com frequência;"),_c('br'),_vm._v("\n                ganhar o respeito de pessoas inteligentes e o afeto das crianças;"),_c('br'),_vm._v("\n                merecer a consideração de críticos honestos e suportar a traição de falsos\n                amigos;"),_c('br'),_vm._v("\n                apreciar a beleza, encontrar o melhor nos outros;"),_c('br'),_vm._v("\n                deixar o mundo um pouco melhor, seja por uma saudável criança, um canteiro\n                de jardim ou uma redimida condição social;"),_c('br'),_vm._v("\n                saber que ao menos uma vida respirou mais fácil porque você viveu. Isso é ter\n                tido sucesso.\"\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-right"},[_vm._v("- Ralph Waldo Emerson")])])])
}]

export { render, staticRenderFns }