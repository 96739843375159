<template>
    <div>
        <section>
            <div class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/policy.jpg"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
        <article>
            <div class="container py-3">
                <h1 class="h3 text-center pb-4">Termos de Uso</h1>

                <div class="pb-3">
                    <h5 class="pb-3">1. Introdução</h5>
                    <p class="text-justify">
                        A MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA lhe convida a
                        visitar o portal Mentes Notáveis e informa os Termos e Condições que regem
                        sua utilização, sobre os quais considera que você dá o seu total
                        consentimento.
                    </p>
                    <p class="text-justify">
                        O conteúdo deste site se destina a disponibilizar aos usuários da internet
                        uma plataforma para interação entre usuários por meio de animações,
                        videoaulas, animadas, jogos didáticos, jogos de lógica, atividades
                        multiplayer e avaliações de Português e Matemática baseadas no PCN
                        (Parâmetros Curriculares Nacionais), com amplo acompanhamento de pais
                        e/ ou responsáveis.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">2. Informação</h5>
                    <p class="text-justify">
                        Nos comprometemos a manter as informações e os materiais contidos nesse
                        portal tão precisos, atualizados e completos quanto possível.
                    </p>
                    <p class="text-justify">
                        No entanto, a atualização, correção e remoção das informações cadastrais
                        dos usuários são de sua responsabilidade, pois são estes que controlam as
                        atividades acima mencionadas. A MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE
                        INFORMÁTICA LTDA não assume a responsabilidade pela utilização, aplicação
                        ou processamento que os usuários possam realizar em relação às mencionadas
                        informações cadastrais em desconformidade com a natureza e função das
                        mesmas.
                    </p>
                    <p class="text-justify">
                        A informação contida neste site poderá ser atualizada ou modificada
                        periodicamente. Por conseguinte, as mesmas não devem ser interpretadas como
                        definitivas.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">3. Privacidade de Nossos Visitantes</h5>
                    <p class="text-justify">
                        Possuímos uma política expressa sobre Privacidade online. As informações
                        de cadastro e outras informações sobre o usuário estão sujeitas ao
                        tratamento definido na referida política.
                    </p>
                    <p class="text-justify">
                        Para mais informações, favor consultar o documento "Política de
                        Privacidade", disponível <a href="/policy">aqui</a>. Tal documento
                        constitui parte integrante dos Termos e Condições de Uso do portal Mentes
                        Notáveis, e deverá ser lido atentamente por todos os usuários antes da
                        aceitação e utilização desse.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">4. Utilização do Site e de Seus Links</h5>
                    <p class="text-justify">
                        Em nenhuma hipótese seremos responsáveis pelo uso de nosso site ou de acesso
                        a links nele indicados, bem como por atos praticados por usuários que tenham
                        por base informações obtidas nos links. Não nos responsabilizamos nem pelo
                        conteúdo nem pelas políticas/ práticas de privacidade dos sites que apontam
                        para o nosso portal e daqueles para os quais apontamos.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">5. Direitos Autorais e Propriedade Intelectual</h5>
                    <p class="text-justify">
                        As animações, videoaulas animadas, jogos didáticos, jogos de lógica,
                        atividades multiplayer e avaliações de Português e Matemática baseadas no
                        PCN (Parâmetros Curriculares Nacionais) presentes no portal estão
                        protegidos por direitos autorais ou outros direitos de propriedade
                        intelectual. Ao acessar a página do site, o usuário declara que irá
                        respeitar todos os direitos de propriedade intelectual e industrial, os
                        decorrentes da proteção de marcas registradas da mesma, bem como todos os
                        direitos referentes a terceiros que, por ventura, estejam, ou estiveram,
                        de alguma forma disponíveis portal. O
                        simples acesso ao site não confere ao usuário qualquer direito de uso dos
                        nomes, títulos, palavras, frases, marcas, patentes, obras literárias,
                        artísticas, literomusicais, entre outras, que nele estejam ou estiveram,
                        disponíveis. Todas as marcas comerciais incluídas neste site são de
                        propriedade da MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA
                        ou lhes foram outorgadas sob licença ou autorização por seus titulares para
                        sua utilização neste site. A reprodução dos conteúdos descritos
                        anteriormente está proibida, salvo prévia autorização por escrito da MENTES
                        NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA e, em hipótese alguma,
                        o usuário adquirirá quaisquer direitos sobre os mesmos. É vedada sua
                        utilização para finalidades comerciais, publicitárias ou qualquer outra
                        que contrarie a realidade para o qual foi concebido conforme definido
                        neste Termo. Restam igualmente proibidas a reprodução, distribuição e
                        divulgação, total ou parcial, das animações, videoaulas animadas, jogos
                        didáticos, jogos de lógica, atividades multiplayer e avaliações de
                        Português e Matemática baseadas no PCN (Parâmetros Curriculares
                        Nacionais) que compõem o presente site, sem prévia e expressa autorização
                        da MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA
                        LTDA. O usuário assume toda e qualquer responsabilidade, de caráter civil e/
                        ou criminal, pela utilização indevida das informações, textos, gráficos,
                        marcas, obras, enfim, todo e qualquer direito de propriedade intelectual ou
                        industrial deste portal.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">6. Cadastro do Usuário, Senha e Segurança</h5>
                    <p class="text-justify">
                        Para que os usuários possam desfrutar das atividades prestadas pelo portal,
                        objeto do presente Termo, faz-se necessário o seu prévio cadastro.
                        O usuário, neste Termo de Uso, concorda em:
                    </p>
                    <p class="text-justify">
                        (a) fornecer informações verdadeiras, exatas, atuais e completas sobre si
                        mesmo no registro no formulário específico para acesso ao site;
                    </p>
                    <p class="text-justify">
                        (b) conservar e atualizar imediatamente tais informações de registro para
                        mantê-las verdadeiras, exatas, atuais e completas. O usuário deverá
                        informar seu próprio endereço eletrônico a ser utilizado para sua
                        identificação no site http://www.mentesnotaveis.com.br/ e recebimento de
                        mensagens advindas de seu cadastro no referido site. O usuário deverá também criar uma senha, sendo inteiramente responsável pela confidencialidade dela, bem como de qualquer atividade que ocorra no âmbito de seu cadastro.
                    </p>
                    <p class="text-justify">
                        O usuário pode alterar sua senha a qualquer momento. Tanto o endereço eletrônico informado pelo usuário quanto a senha por ele criada serão utilizados para a identificação do mesmo e permissão de acesso ao site.
                    </p>
                    <p class="text-justify">
                        Usuários menores de 18 (dezoito) anos ou juridicamente incapazes precisam ser representados ou assistidos, conforme o caso, pelos seus pais ou responsáveis legais para fazer cadastro.
                    </p>
                    <p class="text-justify">
                        As informações da conta do usuário são protegidas por senha para a sua segurança e privacidade. A senha é criptografada em um banco de dados. O usuário concorda em notificar imediatamente o site sobre qualquer uso não-autorizado de seu login (identificação do usuário para acesso ao site mediante o preenchimento de seu endereço eletrônico pessoal e senha criada no site) ou qualquer quebra de segurança de seu conhecimento. Concorda também em não deixar seu cadastro pessoal aberto no computador após entrar com seu endereço eletrônico e senha, evitando, assim, o uso desautorizado por terceiros. Faz parte de nossa política respeitar a privacidade de nossos usuários. O site não irá, portanto, monitorar, editar, acessar ou divulgar informações privativas de seus usuários sem autorização prévia, exceto nos casos de composição da base de ações de marketing da MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA, com a qual o usuário desde já concorda e autoriza, podendo a
                        MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA utilizar os dados cadastrais para ofertas e divulgação de suas marcas e produtos, a menos que sejamos obrigados a fazê-lo mediante ordem judicial ou por força de lei. O usuário autoriza expressamente o site e a MENTES NOTÁVEIS SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA a se comunicar com o mesmo por meio de todos os canais de comunicação disponíveis, incluindo correio eletrônico (e-mail), celular, SMS, entre outros, ficando ressaltado que a principal via de informação para o usuário é o site.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">7. Utilização da Ferramenta "Mensagens"</h5>
                    <p class="text-justify">
                        Usuários cadastrados do site podem enviar e receber mensagens de e para
                        outros usuários do site por meio da ferramenta "Mensagens". Essas
                        mensagens são predeterminadas, e o usuário poderá escolher a mensagem
                        desejada de uma lista.
                    </p>
                    <p class="text-justify">
                        O usuário, desde já, aceita receber mensagens de outros usuários,
                        declarando-se ciente que só poderá se comunicar com os outros usuários
                        por meio das mensagens predeterminadas a exclusivo critério do site.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">8. É de Inteira Responsabilidade do Usuário</h5>
                    <p class="text-justify">
                        a) providenciar o seu próprio acesso à internet e pagar todas as taxas
                        de serviços eventualmente cobradas por terceiros com relação a tal acesso;
                    </p>
                    <p class="text-justify">
                        b) providenciar seu próprio acesso a um endereço para envio de mensagens
                        eletrônicas via internet e pagar todas as taxas de serviço eventualmente
                        cobradas por terceiros com relação a tal acesso;
                    </p>
                    <p class="text-justify">
                        c) providenciar todo o equipamento necessário para efetuar sua conexão à
                        internet, incluindo, mas não se limitando, a um computador e um modem;
                    </p>
                    <p class="text-justify">
                        d) efetuar seu próprio cadastro no site, responsabilizando-se pela
                        correção e veracidade dos dados informados, assim como pela guarda de
                        sua senha de acesso;
                    </p>
                    <p class="text-justify">
                        e) manter o ambiente de seu computador seguro, com uso de ferramentas
                        disponíveis e atualizadas, como antivírus e firewall, entre outras, a
                        fim de contribuir na prevenção de riscos eletrônicos do lado do usuário.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">9. Modificações destes Termos e Condições</h5>
                    <p class="text-justify">
                        Reservamos-nos ao direito de modificar a qualquer momento, visando uma
                        melhoria contínua, de forma unilateral, o presente Termo e Condições
                        de Uso. Ao navegar por este portal, você aceita guiar-se pelos Termos
                        e Condições que se encontram vigentes na data e, portanto, deve verificar
                        os mesmos previamente cada vez que visitar este site.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">10. Exclusão de Garantias e de Responsabilidade</h5>
                    <p class="text-justify">
                        Em decorrência de questões operacionais, de terceirização de serviços, o
                        site Mentes Notáveis e os serviços estão sujeitos a eventuais problemas
                        de interrupção, falha técnica ou indisponibilidade de funcionamento
                        temporário. Quando for razoavelmente possível, o site Mentes Notáveis
                        advertirá previamente as interrupções do funcionamento do site e dos
                        serviços aos seus usuários.
                    </p>
                    <p class="text-justify">
                        O portal Mentes Notáveis <span class="text-uppercase">se exime, com toda
                        a extensão permitida pelo ordenamento jurídico, de qualquer
                        responsabilidade pelos danos e prejuízos de toda natureza que possam
                        decorrer da falta de disponibilidade ou de continuidade do funcionamento
                        do site e dos serviços, à defraudação da utilidade que os usuários possam
                        ter atribuído ao site e aos serviços, à falibilidade do site e dos
                        serviços e, em particular, ainda que não de modo exclusivo, às falhas de
                        acesso às distintas páginas web que compõem o site ou em que se prestam os
                        serviços</span>.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">11. Disposições Gerais</h5>
                    <p class="text-justify">
                        A tolerância do eventual descumprimento de quaisquer cláusulas e
                        condições do presente contrato não constituirá novação das obrigações
                        aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das
                        mesmas a qualquer tempo.
                    </p>
                    <p class="text-justify">
                        O presente instrumento constitui o acordo integral entre as partes,
                        prevalecendo sobre qualquer outro entendimento firmado anteriormente.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">12. Lei Aplicável e Jurisdição</h5>
                    <p class="text-justify">
                        O presente Termo e Condições de Uso é regido pela legislação da República
                        Federativa do Brasil. Seu texto deverá ser interpretado no idioma
                        português e os usuários do site se submetem ao Foro Central da Comarca
                        da Capital do Estado de São Paulo.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">13. Dúvidas</h5>
                    <p class="text-justify">
                        Caso tenha qualquer dúvida em relação ao presente documento, favor
                        entrar em contato por e-mail: contato@mentesnotaveis.com.br.
                    </p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        
    }
</script>