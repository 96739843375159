(function(cash) {
	"use strict";

    window.config = {
        loadPopover: () => {
			$('[data-toggle="popover"]')
				.not('.lazy')
				.popover({
					trigger: 'hover',
					placement: 'bottom',
					html: true
				});
		},
		loadTooltip: () => {
			$('[data-toggle="tooltip"]')
				.not('.lazy')
				.tooltip();
		},
    };

    window.config.loadPopover();
})(cash);