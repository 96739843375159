<template>
    <div>
        <app-banner></app-banner>
        <app-introduction></app-introduction>
        <app-icons></app-icons>
        <app-method></app-method>
        <app-numbers></app-numbers>
    </div>
</template>

<script>
    import Banner from './home/BannerComponent';
    import Introduction from './home/IntroductionComponent';
    import Icons from './home/IconsComponent';
    import Method from './home/MethodComponent';
    import Numbers from './home/NumbersComponent';

    export default {
        components: {
            'app-banner': Banner,
            'app-introduction': Introduction,
            'app-icons': Icons,
            'app-method': Method,
            'app-numbers': Numbers,
        }
    }
</script>