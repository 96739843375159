<template>
    <div>
        <section class="mn-bg-blue text-white pt-5 pb-5">
            <div class="container text-center">
                <h1 class="h2 font-weight-bold">Juntos pela Educação</h1>
                <p>
                    Estamos presentes no ensino privado e público, em mais de 50 escolas e 10
                    municípios, e este número não para de crescer!
                </p>
                <div id="waypoint1" class="row font-weight-bold">
                    <div class="col-6 col-xl-4 col-md-4">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="70">0</span>k
                        </p>
                        <p>USUÁRIOS</p>
                    </div>
                    <div class="col-6 col-xl-4 col-md-4">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="40">0</span>k
                        </p>
                        <p>QUESTÕES</p>
                    </div>
                    <div class="col-12 col-xl-4 col-md-4">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="4.5">0.0</span> milhões
                        </p>
                        <p>PERGUNTAS RESPONDIDAS</p>
                    </div>
                </div>
                <p class="text-justify">
                    No ensino público, mostramos resultados impactantes na melhoria do Índice de
                    Desenvolvimento da Educação Básica (IDEB) e melhores resultados na Prova
                    Brasil.<br>
                    Em Aguaí (SP), o Ideb estava abaixo da meta quando foi iniciada a parceria com
                    as escolas do município. Depois da adoção do sistema Mentes Notáveis por
                    quatro anos, observou-se um crescimento de 17% no índice. Na Prova Brasil,
                    Mentes Notáveis é uma ferramenta decisiva de diagnóstico preciso dos alunos,
                    preparando-o melhor para o teste e oferecendo soluções que corrigem eventuais
                    déficits.
                </p>
            </div>	
        </section>
        <section class="mn-bg-blue-dark text-white pt-5 pb-5">
            <div class="container text-center">
                <h1 class="h2 font-weight-bold">Conteúdos Interativos</h1>
                <div id="waypoint2" class="row font-weight-bold">
                    <div class="col-6 col-xl-3 col-md-3">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="1">0</span>k
                        </p>
                        <p>OBJETOS DE APRENDIZAGEM</p>
                    </div>
                    <div class="col-6 col-xl-3 col-md-3">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="500">0</span>
                        </p>
                        <p>CONTEÚDOS</p>
                    </div>
                    <div class="col-6 col-xl-3 col-md-3">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="230">0</span>
                        </p>
                        <p>VÍDEOS</p>
                    </div>
                    <div class="col-6 col-xl-3 col-md-3">
                        <p class="mn-big-number m-0">
                            +<span class="counter" data-end="370">0</span>
                        </p>
                        <p>JOGOS</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.createWayCounter('#waypoint1');
            this.createWayCounter('#waypoint2');
        },
        methods: {
            createWayCounter(name) {
                new Waypoint({
                    element: $(name),
                    handler: (direction) => {
                        this.counter(name + ' .counter');
                    },
                    offset: '100%'
                });
            },
            counter(name) {
                $(name).each((i, o) => {
                    var el = $(o);
		            var isInt = (el.attr('data-end').indexOf('.') !== -1) ? false : true;

                    $({ counter: 0 }).animate({ counter: el.attr('data-end') }, {
                        duration: 700,
                        easing: 'swing',
                        step: function () {
                            if (isInt) {
                                el.text(Math.ceil(this.counter));
                            } else {
                                el.text(this.counter.toFixed(1));
                            }
                        }
                    });
                });
            }
        }
    }
</script>