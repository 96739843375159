<template>
    <div>
        <app-page-header
            icon="fa fa-exclamation"
            title="Denúncias"
            :breadcrumbs="[
                { title: 'Painel' }
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="complaints"
            :options="{
                ajax: {
                    url: '/web/complaints/list'
                },
                columns: this.columns
            }"
            :actions="{
                edit: {
                    allow: true,
                    url: 'javascript:',
                    callback: onEdit,
                },
            }"
        ></app-datatable>

        <app-complaint-edit
            ref="complaintEdit"
            :onUpdate="reloadTable"
        ></app-complaint-edit>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import DataTable from '../DataTableComponent';
    import ComplaintEdit from './ComplaintEditComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-complaint-edit': ComplaintEdit,
        },
        props: {
            columns: Array,
        },
        methods: {
            onEdit(id) {
                this.$refs.complaintEdit.open(id);
            },
            reloadTable() {
                this.$refs.dataTable.reload();
            }
        }
    }
</script>