<template>
    <div class="about">
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/about.png"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        >
                            <div>
                                <h1
                                    class="font-weight-bold d-none d-sm-block"
                                    data-aos="fade-down"
                                >
                                    Formamos hoje os vencedores de amanhã
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="about1 pt-5 pb-5">
            <div class="container">
                <div class="row text-white text-justify">
                    <div class="col-12 col-xl-6 col-md-6">
                        <h1 class="h2 font-weight-bold">
                            Queremos elevar o patamar do ensino ao Século XXI com um inovador
                            sistema de ensino digital.
                        </h1>
                    </div>
                    <div class="col-12 col-xl-6 col-md-6">
                        <p>
                            Nossa missão é levar inovação às escolas com um inovador sistema de
                            ensino digital complementar para a Educação Básica, que tem como
                            propósito enriquecer o aprendizado das crianças, seguindo diretrizes
                            pedagógicas que priorizam o desenvolvimento dos alunos em habilidades
                            e competências vitais para a vida no século 21.
                        </p>
                        <p>
                            Somos apaixonados por educação e inovação e, acima de tudo, somos
                            pais e mães que acreditam que a educação seja a única maneira de
                            garantir o futuro de nossos filhos.
                        </p>
                        <p>
                            Nos empenhamos constantemente em buscar a excelência e superar
                            expectativas. Queremos despertar a curiosidade, o encantamento, o
                            questionamento e a construção do conhecimento em nossas crianças.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        props: {
            departments: Array
        }
    }
</script>
