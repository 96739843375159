var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('div',{staticClass:"carousel slide carousel-fade",attrs:{"id":"banner","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('img',{staticClass:"img-fluid w-100 banner-img",attrs:{"src":"/assets/img/product.jpg"}}),_vm._v(" "),_c('div',{class:[
                            'carousel-caption d-flex h-100 align-items-center',
                            'justify-content-center m-0 p-0 t-0'
                        ]},[_vm._m(0)])])])])]),_vm._v(" "),_vm._l((_vm.products),function(product){return _c('div',[_c('section',{class:product.name},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 method text-center"},[_c('img',{staticClass:"img-fluid img-icon rounded-circle",attrs:{"data-aos":"zoom-in","src":'/assets/img/icon_' + product.name + '.png'}})])])])]),_vm._v(" "),_c('section',{staticClass:"pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 method"},[_c('h1',{staticClass:"h3 text-center font-weight-bold"},[_vm._v(_vm._s(product.title))]),_vm._v(" "),_c('p',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(product.text)}}),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-lg btn-outline-primary",attrs:{"href":product.link,"target":"_blank"}},[_vm._v("\n                                Ver mais sobre esse produto!\n                            ")])])])])])])])}),_vm._v(" "),_c('section',{staticClass:"testimonial"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-white text-center"},[_c('div',{staticClass:"col-12 col-xl-4 col-md-4 pt-3 pb-3"},[_c('h1',{staticClass:"h4"},[_vm._v("Escolas")]),_vm._v(" "),_c('p',{staticClass:"text-justify"},[_vm._v("\n                        Além de oferecer o melhor conteúdo digital do país, temos foco total no\n                        treinamento e formação continuada de todos os professores envolvidos.\n                    ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-outline-light",attrs:{"href":"javascript:"},on:{"click":_vm.openContact}},[_vm._v("\n                        Solicite uma visita\n                    ")])]),_vm._v(" "),_c('div',{class:[
                        'col-12 col-xl-4 col-md-4 bg-testimonial pt-3 pb-3',
                        'd-flex flex-column justify-content-between'
                    ]},[_c('h1',{staticClass:"h4"},[_vm._v("Pais e Professores")]),_vm._v(" "),_c('p',{staticClass:"text-justify"},[_vm._v("\n                        Prepare-se para viver a mais incrível das experiências: seu ﬁlho ou\n                        aluno te pedir para acessar Mentes Notáveis e estudar!\n                    ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-outline-light",attrs:{"href":"javascript:"},on:{"click":_vm.openVideo}},[_vm._v("\n                        Confira esse depoimento\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-4 pt-3 pb-3"},[_c('h1',{staticClass:"h4"},[_vm._v("Municípios")]),_vm._v(" "),_c('p',{staticClass:"text-justify"},[_vm._v("\n                        Entregamos mensuráveis resultados no processo de ensino-aprendizagem\n                        porque acreditamos grandemente no poder transformador da educação.\n                    ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-outline-light",attrs:{"href":"javascript:"},on:{"click":_vm.openContact}},[_vm._v("\n                        Solicite uma visita\n                    ")])])])])]),_vm._v(" "),_c('app-video-player',{ref:"videoPlayer"}),_vm._v(" "),_c('app-contact',{ref:"contact"})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"h1 font-weight-bold d-none d-sm-block",attrs:{"data-aos":"fade-down"}},[_vm._v("\n                                Alavanque o Futuro da nova geração\n                            ")]),_vm._v(" "),_c('p',{staticClass:"h4 d-none d-md-block",attrs:{"data-aos":"zoom-in"}},[_vm._v("\n                                Ensino qualificado, contextualizado e gameficado\n                            ")])])
}]

export { render, staticRenderFns }