<template>
	<div
		ref="workUs"
		class="modal fade"
		tabindex="-1"
		data-keyboard="false"
		data-backdrop="static"
	>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Trabalhe Conosco</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						:disabled="sending"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form ref="form" enctype="multipart/form-data">
					<div class="modal-body">
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="fa fa-user"></i>
								</span>
							</div>
							<input
								class="form-control"
								type="text"
								name="name"
								placeholder="Nome"
								maxlength="50"
								required
								v-model="data.name"
							>
						</div>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="fa fa-envelope"></i>
								</span>
							</div>
							<input
								class="form-control"
								type="email"
								name="email"
								placeholder="E-mail"
								maxlength="50"
								required
								v-model="data.email"
							>
						</div>
						<div class="input-group mb-4">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="fa fa-file-pdf-o"></i>
								</span>
							</div>
							<div class="custom-file">
								<input
									id="curriculum"
									placeholder="Currículo"
									name="curriculum"
									type="file"
									class="custom-file-input"
									accept="application/pdf"
									required
									@change="loadFile"
								>
								<label class="custom-file-label w-100">
									Anexe currículo .pdf
								</label>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-dismiss="modal"
							:disabled="sending"
						>
							Cancelar
						</button>
						<button
							@click="send"
							type="button"
							class="btn btn-primary"
							:disabled="sending"
						>
							<span
								v-if="sending"
								class="spinner-border spinner-border-sm loading"
								aria-hidden="true"
							></span>
							<span v-if="!sending" class="text">Enviar</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				modal: null,
				form: null,
				sending: false,
				data: {
					name: '',
					email: '',
					curriculum: ''
				}
			}
		},
		mounted() {
			this.modal = $(this.$refs.workUs);
			this.form = $(this.$refs.form);
		},
		methods: {
			open() {
				this.modal.modal('show');
			},
			loadFile(e) {
				this.data.curriculum = e.target.files[0];
			},
			send() {
				this.sending = true;

				let validate = this.validateForm(this.form[0]);

				if (validate != '') {
					Flash('error', validate);
					this.sending = false;
				} else {
					let data = new FormData();

					for (let [key, value] of Object.entries(this.data)) {
						data.append(key, value);
					}

					axios.post('/send_work_us', data)
						.then(res => {
							Swal.fire({
								type: 'success',
								title: 'Enviado',
								text: 'Mensagem enviada com sucesso!'
							});

							this.sending = false;
							this.form.trigger('reset');
							this.modal.modal('hide');
						})
						.catch(err => {
							Flash('error', 'Erro ao enviar mensagem!');
							this.sending = false;
						});
				}
			}
		}
	}
</script>
