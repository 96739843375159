<template>
    <section class="pt-5 pb-5 introduction">
        <div class="container text-center">
            <h1 class="h2 font-weight-bold my-3">Revolucione a sua Escola</h1>
                <div ref="carousel" class="row my-3 text-center carousel">
                    <div
                        v-for="(item, index) in items"
                        class="col-12 col-xl-3 col-md-6 item"
                        data-aos="zoom-in-up"
                        :data-aos-delay="50 * index"
                    >
                        <img class="mx-auto my-2" :src="item.image">
                        <p class="text-justify">{{ item.text }}</p>
                    </div>
                </div>
            <h1 class="h2 font-weight-bold my-3">Educação do Século XXI</h1>
            <p class="text-justify">
                Inovamos o modelo de ensino vigente, proporcionando aos alunos a vivência
                prática dos conteúdos, levando a escola ao universo dos nativos digitais,
                promovendo a motivação, a autonomia e o desenvolvimento da criança nas
                competências e exigências deste século.
            </p>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                carousel: null,
                items: [
                    {
                        text: `Somos uma empresa de tecnologia educacional, 100% brasileira,
                            atuando na Educação Básica, desde 2012`,
                        image: '/assets/img/icons/carousel1.png'
                    },
                    {
                        text: `Produzimos conteúdo digital complementar para ser inserido dentro
                            da proposta curricular escolar convencional`,
                        image: '/assets/img/icons/carousel2.png'
                    },
                    {
                        text: `Elaboramos conteúdos para as disciplinas de Português, Matemática,
                            Ciências e Lógica a partir da BNCC`,
                        image: '/assets/img/icons/carousel3.png'
                    },
                    {
                        text: `Nossas plataformas ajudam na personalização da aprendizagem com
                            relatórios de desempenho do aluno, em tempo real`,
                        image: '/assets/img/icons/carousel4.png'
                    }
                ]
            };
        },
        mounted() {
            this.carousel = $(this.$refs.carousel);

            $(window).on('init.bs.breakpoint', this.onBreakpoint);
            $(window).on('new.bs.breakpoint', this.onBreakpoint);
            bsBreakpoints.init();
        },
        methods: {
            onBreakpoint(e) {
                switch (e.breakpoint) {
                    case 'small':
			        case 'xSmall':
                        this.carousel.find('div').width('100%');
                        this.carousel.removeClass('row my-3');
                        this.carousel.addClass('owl-carousel owl-theme');
                        this.carousel.owlCarousel({
                            items: 1,
                            margin: 20,
                            nav: true,
                            autoWith: false,
                            navText : [
                                '<i class="fa fa-angle-left" aria-hidden="true"></i>',
                                '<i class="fa fa-angle-right" aria-hidden="true"></i>'
                            ],
                        });

                        $('.owl-prev').addClass('btn btn-circle btn-table btn-left');
                        $('.owl-next').addClass('btn btn-circle btn-table btn-right');
                        $('.owl-prev').removeClass('owl-prev');
                        $('.owl-next').removeClass('owl-next');
                        break;
                    default:
                        this.carousel.owlCarousel('destroy');
                        this.carousel.removeClass('owl-carousel');
                        this.carousel.addClass('row my-3');
                }
            }
        }
    }
</script>