<template>
    <div v-if="user">
        <app-page-header
			icon="fa fa-user"
			:title="'Editar Usuário: ' + id"
			:breadcrumbs="[
				{ title: 'Painel' },
				{ title: 'Usuários' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <app-input
                                title="mínimo 3 caracters"
                                name="name"
                                label="Nome Completo"
                                placeholder="Nome"
                                :maxLength="65"
                                :minLength="3"
                                :required="true"
                                :value="user.name"
                            ></app-input>
                            <app-input
                                name="email"
                                label="E-mail"
                                placeholder="E-mail"
                                :maxLength="300"
                                :required="true"
                                :disabled="true"
                                type="email"
                                :value="user.email"
                            ></app-input>
                            <app-input
                                name="username"
                                label="Usuário"
                                placeholder="Usuário"
                                :maxLength="11"
                                :minLength="4"
                                title="apenas letras e números, mínimo 4 caracteres"
                                :disabled="true"
                                :value="user.username"
                            ></app-input>
                            <div v-if="changePass" class="col-12">
                                <fieldset>
                                    <legend>Editar Senha</legend>
                                    <div class="row">
                                        <app-input
                                            name="password"
                                            label="Senha"
                                            placeholder="Senha"
                                            :maxLength="10"
                                            :minLength="6"
                                            :required="true"
                                            :disabled="!changePass"
                                            title="mínimo 6 caracters"
                                            type="password"
                                        ></app-input>
                                        <app-input
                                            name="confirm_password"
                                            label="Confirmar Senha"
                                            placeholder="Confirmar Senha"
                                            :maxLength="10"
                                            :minLength="6"
                                            :required="true"
                                            :disabled="!changePass"
                                            title="mínimo 6 caracters"
                                            type="password"
                                        ></app-input>
                                    </div>
                                </fieldset>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                    <app-link
                                        type="edit"
                                        icon="pencil-square-o"
                                        title="Editar Senha"
                                        :onClick="() => { changePass = !changePass }"
                                    ></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                user: null,
                changePass: false,
            };
        },
        props: {
            id: Number,
            backUrl: String,
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                axios.get(`/web/users/${this.id}`)
                    .then(res => {
                        this.user = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/users/${this.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/gdd';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
            }
        }
    }
</script>