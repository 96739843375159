<template>
    <div>
        <section class="pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 method">
                        <h1 class="h2 font-weight-bold">Ensino Infantil e Fundamental</h1>
                        <p class="text-justify">
                            <img
                                class="float-right m-3 img-fluid"
                                data-aos="zoom-in-up"
                                src="/assets/img/platforms.png"
                            >
                            Nossa plataforma de ensino digital complementar foi elaborada com o
                            objetivo de disponibilizar recursos tecnológicos interdisciplinares para
                            o desenvolvimento de práticas pedagógicas inovadoras que articulam teoria
                            e prática nos processos de ensino e aprendizagem, e, assim, contribuir
                            para a construção das competências e habilidades exigidas pela Base
                            Nacional Comum Curricular (BNCC) e o Sistema Nacional de Avaliação da
                            Educação Básica (SAEB).
                            <br>
                            Os alunos possuem acesso a videoaulas, animações, mais de 40.000 testes
                            de avaliação, jogos educativos e projetos digitais de xadrez, lógica e
                            Lógica de Programação, reforçando o ensino das crianças com os fundamentos
                            de como programar e criar jogos.
                            <br>
                            <a class="btn btn-outline-primary" href="/products">Saiba Mais</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 method">
                        <h1 class="h2 font-weight-bold text-right">Nosso Projeto Notável</h1>
                        <p class="text-justify">
                            <img
                                title="Assistir"
                                data-aos="zoom-in-up"
                                class="float-left m-3 img-thumbnail bt-video img-fluid"
                                src="/assets/img/thumb_video.png"
                                @click="openVideo"
                            >
                            Já pensou se todo processo de ensino e aprendizagem fosse divertido,
                            desaﬁador e respeitasse o ritmo de cada criança?
                            <br>
                            Nós pensamos e criamos um formato no qual a criança recebe todo o
                            conteúdo programático envolvido em uma estimulante jornada na qual,
                            para se sair vencedor e cheio de recompensas, deve cumprir tarefas e
                            missões transmitidas pelos professores por meio de animações, videoaulas,
                            jogos pedagógicos, desaﬁos reais e provas de conhecimento. Nessa dinâmica,
                            o professor acompanha cada evolução do aluno, por meio de relatórios
                            personalizados.
                            <br>
                            Acompanhe nosso vídeo e entenda a metodologia Mentes Notáveis.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        
        <app-video-player ref="videoPlayer"></app-video-player>
    </div>
</template>

<script>
    import VideoPlayer from '../VideoPlayerComponent';

    export default {
        components: {
            'app-video-player': VideoPlayer
        },
        methods: {
            openVideo() {
                this.$refs.videoPlayer.open('jZJ8MUZz_tU');
            }
        }
    }
</script>