<template>
    <div class="banner">
        <section>
            <div class="carousel slide carousel-fade" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#banner" data-slide-to="0" class="active"></li>
                    <li data-target="#banner" data-slide-to="1"></li>
                    <li data-target="#banner" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div
                        v-for="(image, index) in images"
                        :class="[
                            'carousel-item',
                            (index == 0) ? 'active' : ''
                        ]"
                    >
                        <img class="img-fluid w-100 banner-img" :src="image">
                        <div
                            class="carousel-caption d-flex h-100 align-items-center
                                justify-content-center m-0 p-0 t-0"
                        >
                            <div class="user-select-none">
                                <h1
                                    class="font-weight-bold d-none d-sm-block"
                                    data-aos="fade-down"
                                >
                                    FAÇA DA EDUCAÇÃO SUA MARCA DE GESTÃO
                                </h1>
                                <p class="d-none d-md-block" data-aos="fade-in">
                                    <span>Promova melhores resultados no ensino!</span>
                                </p>
                                <p class="m-0 d-none d-xl-block" data-aos="fade-in">
                                    Democratize o acesso ao ensino de qualidade alinhado
                                    com a BNCC<br>
                                    Revolucione o processo de aprendizagem com inovação,
                                    tecnologia,<br>
                                    neurociência e os mais recentes avanços na área da educação
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blackboard">
            <div class="container text-center">
                <div class="row">
                    <div class="col-12 col-xl-9 col-md-7 d-flex">
                        <p class="text-white m-0 text-xl-left text-md-left align-self-center">
                            Vamos juntos transformar a EDUCAÇÃO!
                        </p>
                    </div>
                    <div
                        class="col-12 col-xl-3 col-md-5 text-xl-right text-md-right
                            align-self-center"
                    >
                        <a
                            class="btn btn-success btn-lg my-2"
                            href="javascript:"
                            @click="openForm"
                        >
                            Agende uma Visita
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <app-contact ref="contact"></app-contact>
    </div>
</template>

<script>
    import Contact from '../ContactComponent';

    export default {
        components: {
            'app-contact': Contact,
        },
        data() {
            return {
                images: [
                    '/assets/img/banners/banner1.jpg',
                    '/assets/img/banners/banner2.jpg',
                    '/assets/img/banners/banner3.jpg',
                ]
            };
        },
        methods: {
            openForm() {
                this.$refs.contact.open();
            }
        }
    }
</script>