<template>
    <section class="mn-bg-icons">
        <div class="container">
            <div class="row mx-auto justify-content-center">
                <div
                    v-for="(icon, index) in icons"
                    :class="[
                        'd-flex frame text-center',
                        ([0,1,8,9].includes(index)) ? 'frame-w' : ''
                    ]"
                    data-aos="zoom-in"
                    :data-aos-delay="50 * index"
                >
                    <img :src="icon.image" class="img-fluid">
                    <div class="hover d-table position-absolute" style="opacity: 0.0;">
                        <span class="p-2 text-white d-table-cell align-middle">
                            {{ icon.text }}
                        </span>
                    </div>
                    <div class="cover position-absolute d-table">
                        <div class="d-table-cell align-middle">
                            <img :src="icon.icon" class="img-fluid">
                            <p class="font-weight-bold text-white position-relative mb-0">
                                {{ icon.title }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                icons: [
                    {
                        image: '/assets/img/frames/frame1.png',
                        icon: '/assets/img/icons/icon1.png',
                        title: 'ANÁLISE CRÍTICA',
                        text: `Analisar criticamente uma situação, instigando o interesse por
                            investigação`
                    },
                    {
                        image: '/assets/img/frames/frame2.png',
                        icon: '/assets/img/icons/icon2.png',
                        title: 'LIDERANÇA',
                        text: `Tomar decisões, desenvolvendo a autonomia e a automotivação`
                    },
                    {
                        image: '/assets/img/frames/frame3.png',
                        icon: '/assets/img/icons/icon3.png',
                        title: 'CRIATIVIDADE',
                        text: `Solucionar problemas e necessidades, desenvolvendo diferentes
                            estratégias`
                    },
                    {
                        image: '/assets/img/frames/frame4.png',
                        icon: '/assets/img/icons/icon4.png',
                        title: 'RESILIÊNCIA',
                        text: `Não desistir frente a desaﬁos, aprendendo com os próprios erros,
                            enfrentando frustrações e atingindo objetivos`
                    },
                    {
                        image: '/assets/img/frames/frame5.png',
                        icon: '/assets/img/icons/icon5.png',
                        title: 'EMPREENDEDORISMO',
                        text: `Criar um trabalho de própria autoria, se tornando protagonista
                            e autor do próprio aprendizado`
                    },
                    {
                        image: '/assets/img/frames/frame6.png',
                        icon: '/assets/img/icons/icon6.png',
                        title: 'SOCIOEMOCIONAL',
                        text: `Adaptar-se constantemente a diversas situações ou circunstâncias,
                            desenvolvendo habilidades socioemocionais`
                    },
                    {
                        image: '/assets/img/frames/frame7.png',
                        icon: '/assets/img/icons/icon7.png',
                        title: 'COMUNICAÇÃO',
                        text: `Comunicar-se por escrito e oralmente, construindo e compartilhando
                            conhecimento`
                    },
                    {
                        image: '/assets/img/frames/frame8.png',
                        icon: '/assets/img/icons/icon8.png',
                        title: 'LÓGICA',
                        text: `Desenvolver o raciocínio lógico, disciplinado e abstrato`
                    },
                    {
                        image: '/assets/img/frames/frame9.png',
                        icon: '/assets/img/icons/icon9.png',
                        title: 'EMPATIA',
                        text: `Trabalhar em equipe de forma cooperativa, desenvolvendo o respeito
                            ao próximo, unindo diferentes idéias`
                    },
                    {
                        image: '/assets/img/frames/frame10.png',
                        icon: '/assets/img/icons/icon10.png',
                        title: 'RESPONSABILIDADE',
                        text: `Compreender necessidades, cumprindo tarefas solicitadas
                            ou necessárias`
                    },
                ]
            };
        },
        mounted() {
            $('.frame .cover').hover(
                (e) => {
                    const EL = $(e.target);
                    EL.parent().parent().find('.hover').animate({ opacity: 1.0 }, 100);
                    EL.animate({ opacity: 0.0 }, 100);
                    
                    return false;
                },
                (e) => {
                    const EL = $(e.target);
                    EL.parent().parent().find('.hover').animate({ opacity: 0.0 }, 100);
                    EL.animate({ opacity: 1.0 }, 100);
                    
                    return false;
                }
            );
        }
    }
</script>