<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/policy.jpg"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
        <article>
            <div class="container p-3">
                <h1 class="h3 text-center pb-4">Política de Privacidade</h1>

                <div class="pb-3">
                    <p class="text-justify">
                        Para fins desta política, devem ser observadas as seguintes considerações:
                    </p>
                    <ol>
                        <li>
                            <p class="text-justify">
                                O portal Mentes Notáveis coleta informações pessoais capazes de
                                identificar os usuários quando: a) cadastram-se em nosso portal; b)
                                participam de nossas promoções, ações e concursos; c) entram em
                                contato conosco pelos canais de atendimento ao usuário.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Para cada uma das modalidades de coleta de dados poderão ser
                                solicitadas diferentes informações, de acordo com a finalidade da
                                sua coleta. Assim, os usuários serão sempre informados sobre os
                                dados que estão sendo coletados, ficando a seu critério fornecê-los
                                ou não, e, em cada caso, eles serão avisados também sobre as
                                consequências de sua decisão.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                O portal Mentes Notáveis também recebe e armazena automaticamente,
                                por meio de cookies, informações em seus servidores sobre as
                                atividades advindas do navegador, incluindo endereço IP e a página
                                acessada.
                            </p>
                            <p class="text-justify">
                                Um cookie é uma pequena quantidade de informação que, geralmente,
                                inclui um identificador anônimo único. Alguns sites enviam para
                                seu navegador para que seja guardado no disco rígido do computador,
                                permitindo sua identificação na próxima vez que retornar ao site.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                A entrada de dados pessoais do usuário no portal Mentes Notáveis,
                                bem como a aceitação de cookies de navegação, não constitui
                                requisito para navegar pelo mesmo, sendo facultativo o seu
                                fornecimento. O usuário que decide dar entrada a seus dados
                                pessoais declara conhecer e aceitar os termos da presente.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Todas as informações coletadas dos usuários trafegam de forma
                                segura, utilizando processo de criptografia padrão de internet.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Qualquer informação fornecida pelos usuários será armazenada e
                                envidaremos nossos melhores esforços na manutenção da
                                confidencialidade, integridade e disponibilidade das informações
                                que nos forem fornecidas.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Todos os dados pessoais coletados serão incorporados ao banco de
                                dados do portal Mentes Notáveis, pertencente à MENTES NOTÁVEIS
                                SISTEMAS E CONSULTORIA DE INFORMÁTICA LTDA, com sede na Alameda
                                Jaú, 48, 11.º andar,
                                Jardim Paulista, São Paulo/SP.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                O portal Mentes Notáveis utiliza as informações coletadas para os
                                seguintes propósitos gerais: (i) informar a respeito de novos
                                produtos da empresa e de parceiros; (ii) manter atualizados os
                                cadastros dos usuários para fins de contato telefônico, por
                                correio eletrônico, mala direta, SMS ou outros meios de
                                comunicação; (iii) otimizar a usabilidade e a experiência
                                interativa durante a navegação do usuário no site; (iv)
                                elaborar estatísticas gerais, sem que haja identificação dos
                                usuários; (v) responder às dúvidas e solicitações de seus
                                usuários; (vi) realizar campanhas de comunicação e marketing
                                de relacionamento, bem como divulgar
                                ofertas especiais de parceiros; (vii) comunicar-se com os usuários,
                                a fim de lhes dar informações sobre a empresa.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                O acesso às informações coletadas é restrito aos funcionários e
                                pessoas autorizadas pelo portal Mentes Notáveis. Aqueles que se
                                utilizarem indevidamente dessas informações, ferindo nossa
                                Política de Privacidade, estarão sujeitos às penalidades, sem
                                exclusão das demais medidas legais cabíveis.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Salvo por determinação judicial, as informações dos usuários
                                cadastrados no ambiente deste site (www.mentesnotaveis.com.br)
                                jamais serão transferidas a terceiros que não sejam parceiros,
                                empresas autorizadas ou usadas para finalidades diferentes
                                daquelas para as quais foram coletadas pelo portal Mentes Notáveis.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                O usuário garante a veracidade e exatidão dos dados pessoais que
                                fornecer neste site. Ou seja, o portal Mentes Notáveis não tem
                                qualquer responsabilidade no caso de inserção de dados falsos ou
                                inexatidão dos dados pessoais introduzidos pelo usuário neste site.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                O usuário que introduzir seus dados pessoais identificáveis
                                poderá, a qualquer momento, alterar, corrigir e remover como for
                                conveniente para este, bastando, para tanto, fazê-las por meio de
                                seu próprio cadastro no site. O usuário, em hipótese alguma,
                                deverá fornecer seus dados por e-mail, por se tratar de um ambiente
                                sem os requisitos adequados de segurança. Dessa forma, o portal
                                Mentes Notáveis exime-se de qualquer responsabilidade quanto à
                                correção, alteração ou remoção de dados.
                            </p>
                            <p class="text-justify">
                                Por razões de segurança, certas alterações cadastrais, incluindo
                                todos os dados referentes ao cadastro de assinantes do portal
                                Mentes Notáveis, só podem ser feitas com identificação por meio
                                de e-mail e senha.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                As newsletters e mensagens publicitárias enviadas por e-mail
                                trarão, obrigatoriamente, opção de cancelamento do envio daquele
                                tipo de mensagem por parte do portal Mentes Notáveis. A
                                solicitação será atendida com a maior presteza possível.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Os serviços de envio de e-mails pode ou não ser realizado por uma
                                empresa contratada pelo portal Mentes Notáveis, que utiliza seus
                                próprios servidores para realizar o envio.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                A empresa contratada não armazena nem utiliza de nenhuma forma
                                e sob nenhuma hipótese os e-mails do cadastro do portal Mentes
                                Notáveis para qualquer outro fim que não o envio das mensagens,
                                de acordo com as preferências de cada usuário registradas no portal
                                Mentes Notáveis.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                É de responsabilidade do usuário a guarda de sua senha e de seu
                                login de acesso. Não é adequada a utilização de senhas óbvias,
                                como datas especiais, nomes ou sequências numéricas. Caso o
                                usuário tome conhecimento ou apenas suspeite que sua senha foi
                                descoberta, ele deverá alterá-la em sua página de cadastro
                                imediatamente.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Ao tomar conhecimento de alguma promoção realizada pelo portal
                                Mentes Notáveis, consulte-o para verificar a veracidade da mesma.
                                Caso a informação seja equivocada, desconsidere-a.
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Ainda que coletivamente, os dados só podem ser utilizados para os
                                fins previstos nesta Política de Privacidade.
                            </p>
                        </li>
                    </ol>
                </div>
                <h4 class="text-center pt-3 pb-4">Glossário</h4>
                <div class="pb-3">
                    <h5 class="pb-3">Antivírus</h5>
                    <p class="text-justify">
                        Programa ou software especificamente desenvolvido para detectar, anular
                        e eliminar de um computador vírus e outros tipos de códigos maliciosos.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Cookies</h5>
                    <p class="text-justify">
                        Um pequeno conjunto de informações armazenado no seu computador por
                        alguns sites.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Criptografia</h5>
                    <p class="text-justify">
                        É a ciência e arte de escrever mensagens em forma cifrada ou em código.
                        É parte de um campo de estudos que trata das comunicações secretas.
                        usada, entre outras finalidades, para: a) autenticar a identidade de
                        usuários; b) autenticar transações bancárias; c) proteger a integridade
                        de transferências eletrônicas de fundos e proteger o sigilo de
                        comunicações pessoais e comerciais.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Endereço IP</h5>
                    <p class="text-justify">
                        Esse endereço é um número único para cada computador conectado à
                        internet, composto por uma sequência de quatro números que variam de 0
                        até 255 separados por ".". Por exemplo: 192.168.34.25.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Senha</h5>
                    <p class="text-justify">
                        Conjunto de caracteres, de conhecimento único do usuário, utilizado no
                        processo de verificação de sua identidade, assegurando que ele realmente
                        é quem diz ser.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Spam</h5>
                    <p class="text-justify">
                        Termo usado para se referir aos e-mails não solicitados, que
                        geralmente são enviados para um grande número de pessoas.
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">SMS</h5>
                    <p class="text-justify">
                        Serviço de mensagens curtas ou Short Message Service (SMS), está
                        disponível em telefones celulares (telemóveis) digitais que permitem
                        o envio de mensagens curtas entre esses equipamentos e entre outros
                        dispositivos de mão, como palm e handheld, e até entre telefones fixos
                        (linha-fixa).
                    </p>
                </div>
                <div class="pb-3">
                    <h5 class="pb-3">Vírus</h5>
                    <p class="text-justify">
                        Programa ou parte de um programa de computador, normalmente malicioso,
                        que se propaga infectando. Isto é, inserindo cópias de si mesmo e se
                        tornando parte de outros programas e arquivos de um computador. O vírus
                        depende da execução do programa ou arquivo hospedeiro para que possa se
                        tornar ativo e dar continuidade ao processo de infecção.
                    </p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {

    }
</script>