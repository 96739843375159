<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/product.jpg"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        >
                            <div>
                                <h1
                                    class="h1 font-weight-bold d-none d-sm-block"
                                    data-aos="fade-down"
                                >
                                    Alavanque o Futuro da nova geração
                                </h1>
                                <p class="h4 d-none d-md-block" data-aos="zoom-in">
                                    Ensino qualificado, contextualizado e gameficado
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div v-for="product in products">
            <section :class="product.name">
                <div class="container">
                    <div class="row">
                        <div class="col-12 method text-center">
                            <img
                                class="img-fluid img-icon rounded-circle"
                                data-aos="zoom-in"
                                :src="'/assets/img/icon_' + product.name + '.png'"
                            >
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 method">
                            <h1 class="h3 text-center font-weight-bold">{{ product.title }}</h1>
                            <p class="text-justify" v-html="product.text"></p>
                            <div class="text-center">
                                <a
                                    class="btn btn-lg btn-outline-primary"
                                    :href="product.link"
                                    target="_blank"
                                >
                                    Ver mais sobre esse produto!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="testimonial">
            <div class="container">
                <div class="row text-white text-center">
                    <div class="col-12 col-xl-4 col-md-4 pt-3 pb-3">
                        <h1 class="h4">Escolas</h1>
                        <p class="text-justify">
                            Além de oferecer o melhor conteúdo digital do país, temos foco total no
                            treinamento e formação continuada de todos os professores envolvidos.
                        </p>
                        <a
                            class="btn btn-outline-light"
                            href="javascript:"
                            @click="openContact"
                        >
                            Solicite uma visita
                        </a>
                    </div>
                    <div :class="[
                            'col-12 col-xl-4 col-md-4 bg-testimonial pt-3 pb-3',
                            'd-flex flex-column justify-content-between'
                        ]"
                    >
                        <h1 class="h4">Pais e Professores</h1>
                        <p class="text-justify">
                            Prepare-se para viver a mais incrível das experiências: seu ﬁlho ou
                            aluno te pedir para acessar Mentes Notáveis e estudar!
                        </p>
                        <a
                            class="btn btn-outline-light"
                            href="javascript:"
                            @click="openVideo"
                        >
                            Confira esse depoimento
                        </a>
                    </div>
                    <div class="col-12 col-xl-4 col-md-4 pt-3 pb-3">
                        <h1 class="h4">Municípios</h1>
                        <p class="text-justify">
                            Entregamos mensuráveis resultados no processo de ensino-aprendizagem
                            porque acreditamos grandemente no poder transformador da educação.
                        </p>
                        <a
                            class="btn btn-outline-light"
                            href="javascript:"
                            @click="openContact"
                        >
                            Solicite uma visita
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <app-video-player ref="videoPlayer"></app-video-player>
        <app-contact ref="contact"></app-contact>
    </div>
</template>

<script>
    import VideoPlayer from '../VideoPlayerComponent';
    import Contact from '../ContactComponent';

    export default {
        components: {
            'app-video-player': VideoPlayer,
            'app-contact': Contact
        },
        data() {
            return {
                products: [
                    {
                        title: 'Jovens Notáveis',
                        name: 'jovensnotaveis',
                        link: 'https://www.jovensnotaveis.com.br',
                        text: `Jovens Notáveis é um inovador sistema de ensino digital complementar,
                            projetado para crianças de 6 a 14 anos.<br><br>
                            Nosso conteúdo é desenvolvido a partir da Base Nacional Comum
                            Curricular (BNCC), adotada pelo Ministério da Educação. Ensinamos
                            Linguagens - Língua Portuguesa, Língua Estrangeira, Arte e Educação
                            Física -, Matemática, Ciências da Natureza e Ciências Humanas -
                            História e Geografia - melhorando todo o processo de alfabetização,
                            incluindo a discussão de valores como ética, cidadania e meio
                            ambiente, tornando o aprendizado divertido e eficaz.<br><br>
                            No Mentes Notáveis, construímos todo um sistema de ensino gameficado,
                            que abrange projetos e dispositivos na plataforma, proporcionando
                            muitos benefícios aos alunos e professores.`
                    },
                    {
                        title: 'Jardim Notável',
                        name: 'jardimnotavel',
                        link: 'https://www.jardimnotavel.com.br/choose',
                        text: `Jardim Notável é um projeto inovador de ensino digital,
                            desenvolvido especialmente para alunos da Educação Infantil,
                            crianças de 4 a 5 anos e 11 meses.<br><br>
		                    Estudos comprovam que é na primeira infância que se estabelecem
                            as bases de aprendizado que serão utilizadas ao longo de toda a
                            vida da criança. É por isso que se legitima ainda mais a importância
                            de se promover o desenvolvimento integral da criança através de
                            experiências significativas de aprendizagem, aliando o Cuidar e o
                            Educar a modernos recursos didático-tecnológicos.<br><br>
		                    Os conteúdos abordados no projeto Jardim Notável são fundamentados
                            nas Normas Curriculares do RCNEI (Referencial Curricular Nacional de
                            Educação Infantil) e nas Diretrizes Curriculares Nacionais para a
                            Educação Infantil, e seguem estudos e pesquisas de especialistas
                            sobre a primeira infância.`
                    },
	                {
                        title: 'Easy Chess',
                        name: 'easychess',
                        link: 'https://www.easychess.com.br',
                        text: `Ao jogar xadrez, o aluno adquire maior desenvoltura e maturidade
                            intelectual. Segundo estudos de psicólogos da Universidade de La
                            Laguna (Ilhas Canárias - Espanha), a prática do xadrez melhora,
                            nos estudantes de 6 a 16 anos, a capacidade de captação e abstração
                            verbal, atenção, resistência à distração, organização perceptiva,
                            análise, síntese, coordenação visual-motora, rapidez, planejamento
                            e previsão.`
                    },
	                {
                        title: 'UCode',
                        name: 'ucode',
                        link: 'https://ucode.codingnotavel.com.br',
                        text: `É o nosso projeto de Lógica de Programação, que visa introduzir
                            os alunos à tecnologia como ferramenta de criação.<br><br>
		                    Os alunos aprendem por meio de desafios lúdicos que os auxiliam
                            na construção da capacidade lógica e de abstração, base para o
                            pensamento computacional.<br><br>
		                    O aluno aprende a analisar os cenários e propor idéias para
                            resolvê-los e melhorá-los, usando a criatividade, a percepção e a
                            atitude.`
                    },
                ]
            };
        },
        methods: {
            openVideo() {
                this.$refs.videoPlayer.open('e_BVwtKv4GQ');
            },
            openContact() {
                this.$refs.contact.open();
            },
        }
    }
</script>