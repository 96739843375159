require('./bootstrap');

import './config';
import './custom-file-input';
import './flash';
import './utils/help';

import Vue from 'vue';
import Validator from './plugins/validator';
import AddScriptPlugin from './plugins/addScripts';
import DatatablesButtons from './plugins/datatablesButtons';
import VueMask from 'v-mask';

Vue.use(Validator);
Vue.use(AddScriptPlugin);
Vue.use(DatatablesButtons);
Vue.use(VueMask);

import Header from './components/HeaderComponent';
import Footer from './components/FooterComponent';

import Home from './components/HomeComponent';
import Terms from './components/pages/TermsComponent';
import Policy from './components/pages/PolicyComponent';
import About from './components/pages/AboutComponent';
import Product from './components/pages/ProductComponent';
import Social from './components/pages/SocialComponent';

import Complaint from './components/complaints/ComplaintComponent';
import ComplaintCreate from './components/complaints/ComplaintCreateComponent';
import ComplaintConsult from './components/complaints/ComplaintConsultComponent';
import ComplaintList from './components/complaints/ComplaintListComponent';

import Cover from './components/CoverComponent';
import Login from './components/LoginComponent';
import Side from './components/SideComponent';
import HeaderPanel from './components/HeaderPanelComponent';
import FooterPanel from './components/FooterPanelComponent';
import UserEdit from './components/users/UserEditComponent';

const app = new Vue({
    el: '#app',
    components: {
        'app-header': Header,
        'app-footer': Footer,
        'app-home': Home,

        'app-terms': Terms,
        'app-policy': Policy,
        'app-about': About,
        'app-product': Product,
        'app-social': Social,

        'app-complaint': Complaint,
        'app-complaint-create': ComplaintCreate,
        'app-complaint-consult': ComplaintConsult,
        'app-complaint-list': ComplaintList,

        'app-cover': Cover,
        'app-login': Login,
        'app-side': Side,
        'app-header-panel': HeaderPanel,
        'app-footer-panel': FooterPanel,

        'app-user-edit': UserEdit,
    }
});