<template>
    <aside class="app-sidebar">
		<div class="app-sidebar__user">
			<img :src="user.image" class="app-sidebar__user-avatar">
			<div class="col-8 p-0">
				<p class="app-sidebar__user-name">{{ user.name }}</p>
				<p class="app-sidebar__user-designation text-truncate">
					Gerente
				</p>
			</div>
		</div>
	</aside>
</template>

<script>
    export default {
        props: {
            user: Object
        }
    }
</script>