<template>
    <header>
        <nav
            ref="nav"
            class="navbar navbar-expand-lg navbar-light fixed-top navbar-expand-md"
        >
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img src="/assets/img/logo.png" alt="logo Mentes Notáveis">
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-controls="navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span>
                        <i class="fa fa-bars"></i>
                    </span>
                </button>
                <div id="navbar" class="collapse navbar-collapse justify-content-end rounded">
                    <ul class="navbar-nav">
                        <li class="nav-item ml-1 mr-1">
                            <a href="about" class="nav-link btn font-weight-bold">SOBRE NÓS</a>
                        </li>
                        <li class="nav-item ml-1 mr-1">
                            <a href="products" class="nav-link btn font-weight-bold">PRODUTOS</a>
                        </li>
                        <li></li>
                        <li class="nav-item ml-1 mr-1">
                            <a href="social" class="nav-link btn font-weight-bold">SOCIAL</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a
                                href="javascript:"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                class="nav-link dropdown-toggle btn font-weight-bold"
                            >
                                ACESSAR
                            </a>
                            <div class="dropdown-menu">
                                <a
                                    href="https://www.jovensnotaveis.com.br"
                                    class="dropdown-item btn p-2"
                                >
                                    <img src="/assets/img/icon-bt-jovens-notaveis.png">
                                    JOVENS NOTÁVEIS
                                </a>
                                <a
                                    href="https://www.jardimnotavel.com.br/choose"
                                    class="dropdown-item btn p-2"
                                >
                                    <img src="/assets/img/icon-bt-jardim-notavel.png">
                                    JARDIM NOTÁVEL
                                </a>
                                <a
                                    href="https://www.easychess.com.br"
                                    class="dropdown-item btn p-2"
                                >
                                    <img src="/assets/img/icon-bt-easy-chess.png">
                                    EASY CHESS
                                </a>
                                <a
                                    href="https://ucode.codingnotavel.com.br"
                                    class="dropdown-item btn p-2"
                                >
                                    <img src="/assets/img/icon-bt-ucode.png">
                                    UCODE
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                nav: null
            };
        },
        mounted() {
            this.nav = $(this.$refs.nav);

            $(document).on('scroll', this.onScrolling);
            this.onScrolling();
        },
        methods: {
            onScrolling() {
                const SCROLL_TOP = $(document).scrollTop();
                const NAV_H = this.nav.height();

                this.nav.toggleClass('scrolled', SCROLL_TOP > NAV_H);
		        this.nav.toggleClass('shrink', SCROLL_TOP > NAV_H);
            }
        }
    }
</script>