<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/complaints.png"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
        <article>
            <div class="container py-3 user-select-none">
                <h1 class="h3 text-center pb-4">Canal de Ética</h1>
                <div v-if="search" class="pb-3">
                    <h5 class="pb-3">Acompanhar relato</h5>
                    <p>
                        Para acompanhar o andamento do seu relato, por favor, digite o número do
                        seu protocolo no campo abaixo e clique no botão “Consultar protocolo”:
                    </p>
                    <form ref="formSearch">
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Protocolo"
                                minlength="11"
                                maxlength="20"
                                required
                                v-model="protocol"
                            >
                        </div>
                    </form>
                    <div class="pb-3">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="consult"
                            :disabled="searching"
                        >

                            <span
                                v-if="searching"
                                class="spinner-border spinner-border-sm loading"
                                aria-hidden="true"
                            ></span>
                            <span v-if="!searching" class="text">Consultar protocolo</span>
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="cancel"
                            :disabled="searching"
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
                <div v-else class="pb-3">
                    <h5 class="pb-3">Informações da denúncia</h5>
                    <div class="row">
                        <div class="col-12">
                            <p class="border border-secondary rounded p-2">
                                <strong>Protocolo</strong>
                            </p>
                            <p>{{ complaint.protocol }}</p>
                        </div>
                        <div class="col-12">
                            <p class="border border-secondary rounded p-2">
                                <strong>Data de criação</strong>
                            </p>
                            <p>{{ new Date(complaint.created_at).toLocaleString() }}</p>
                        </div>
                        <div class="col-12">
                            <p class="border border-secondary rounded p-2">
                                <strong>Situação atual</strong>
                            </p>
                            <p>{{ complaint.status_text }}</p>
                        </div>
                        <div class="col-12">
                            <p class="border border-secondary rounded p-2">
                                <strong>Última atualização</strong>
                            </p>
                            <p>{{ new Date(complaint.updated_at).toLocaleString() }}</p>
                        </div>
                    </div>
                    <hr />
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="cancel"
                    >
                        Voltar a tela inicial
                    </button>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: true,
                searching: false,
                protocol: '',
                complaint: null,
            };
        },
        methods: {
            consult() {
                this.searching = true;
                let validate = this.validateForm(this.$refs.formSearch);

                if (validate != '') {
                    Flash('error', validate);
                    this.searching = false;
                } else {
                    axios.get(`/web/complaints/${this.protocol}`)
                        .then(res => {
                            this.searching = false;
                            this.search = false;

                            this.complaint = res.data;
                        })
                        .catch(err => {
                            Flash('error', 'Protocolo não encontrado!');
                            this.searching = false;
                        });
                }
            },
            cancel() {
                window.location.href = '/complaints';
            }
        }
    }
</script>
